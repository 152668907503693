import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loading';
import { useLang } from '../../helpers/language';
import parse from 'html-react-parser';
import { Checkbox, Input } from '@mantine/core';
import NProgress from 'nprogress';

export default function Role() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.role;
	const { lang } = useLang();

	const [index, setindex] = useState('');
	const [nameEn, setNameEn] = useState('');
	const [nameRu, setNameRu] = useState('');
	const [nameHy, setNameHy] = useState('');
	const [descEn, setDescEn] = useState('');
	const [descRu, setDescRu] = useState('');
	const [descHy, setDescHy] = useState('');
	const [updatedAt, setUpdatedAt] = useState('');
	const [permissions, setPermissions] = useState([]);
	const [allPermissions, setAllPermissions] = useState([]);

	const modelSendToServer = {
		index,
		name: {
			en: nameEn,
			ru: nameRu,
			hy: nameHy,
		},
		desc: {
			en: descEn,
			ru: descRu,
			hy: descHy,
		},
		updatedAt,
		permissions,
	};

	const handleChange = async (row, crud) => {
		if (crud !== 'read' && row.crud.read === false) {
			let temproraryPermissions = permissions.map((permission) => {
				if (permission._id === row._id) {
					permission.crud[crud] = !permission.crud[crud];
					permission.crud['read'] = true;
				}
				return permission;
			});
			setPermissions([...temproraryPermissions]);
		} else if (crud !== 'read' && row.crud.read === true) {
			let temproraryPermissions = permissions.map((permission) => {
				if (permission._id === row._id) {
					permission.crud[crud] = !permission.crud[crud];
				}
				return permission;
			});
			setPermissions([...temproraryPermissions]);
		} else if (crud === 'read') {
			let temproraryPermissions = permissions.map((permission) => {
				if (permission._id === row._id) {
					if (permission.crud[crud]) {
						permission.crud['create'] = false;
						permission.crud['read'] = false;
						permission.crud['update'] = false;
						permission.crud['delete'] = false;
					} else {
						permission.crud[crud] = !permission.crud[crud];
					}
				}
				return permission;
			});
			setPermissions([...temproraryPermissions]);
		}
	};

	const columns = [
		// name
		{
			accessorKey: 'name',
			header: config.translate.name[lang],
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				let result = parse(row?.name ? row.name : '...');
				return result;
			},
		},
		// create
		{
			accessorKey: 'crud.create',
			header: 'Create',
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return (
					<Checkbox
						checked={cell}
						onChange={(e) => handleChange(row, 'create')}
					/>
				);
			},
		},
		// read
		{
			accessorKey: 'crud.read',
			header: 'Read',
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				let result = (
					<Checkbox
						checked={cell}
						onChange={(e) => handleChange(row, 'read')}
					/>
				);
				return result;
			},
		},
		// update
		{
			accessorKey: 'crud.update',
			header: 'Update',
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				let result = (
					<Checkbox
						checked={cell}
						onChange={(e) => handleChange(row, 'update')}
					/>
				);
				return result;
			},
		},
		// delete
		{
			accessorKey: 'crud.delete',
			header: 'Delete',
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				let result = (
					<Checkbox
						checked={cell}
						onChange={(e) => handleChange(row, 'delete')}
					/>
				);
				return result;
			},
		},
	];

	useEffect(() => {
		(async () => {
			NProgress.start();
			// get all permissions from server
			var Allpermissions = await API.get(config.api.permission, {});
			setAllPermissions(Allpermissions);

			if (id !== 'add') {
				let currentItem = await API.get(route, {
					_id: id,
				});
				setindex(currentItem[0]?.index);
				setNameEn(currentItem[0]?.name.en);
				setNameRu(currentItem[0]?.name.ru);
				setNameHy(currentItem[0]?.name.hy);
				setDescEn(currentItem[0]?.desc.en);
				setDescRu(currentItem[0]?.desc.ru);
				setDescHy(currentItem[0]?.desc.hy);
				setUpdatedAt(currentItem[0]?.updatedAt);
				setPermissions(currentItem[0]?.permissions);

				if (Allpermissions && Allpermissions.length > 0) {
					let tempPermissions = currentItem?.[0]?.permissions
						? [...currentItem[0].permissions]
						: [];

					Allpermissions.forEach((permission) => {
						let existingPermission = tempPermissions.find(
							(x) => x._id === permission._id
						);

						if (existingPermission) {
							// Merge inputs, preferring existingPermission inputs over permission inputs
							let mergedInputs = [
								...(existingPermission.inputs || []),
							];

							(permission.inputs || []).forEach((input) => {
								if (
									!mergedInputs.some(
										(x) => x.name === input.name
									)
								) {
									mergedInputs.push(input);
								}
							});

							existingPermission.inputs = mergedInputs;
						} else {
							tempPermissions.push({
								_id: permission._id,
								name: permission.name,
								crud: {
									create: false,
									read: false,
									update: false,
									delete: false,
								},
								inputs: permission.inputs || [],
							});
						}
					});

					setPermissions([...tempPermissions]);
				}
			}

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				hardReload={true}
				id={id}
				permissionModel='role'
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: index,
						setter: setindex,
						type: 'number',
						usageType: 'number',
						label: config.translate.index[lang],
						placeholder: 'Index',
						permissionModel: 'index',
					},
					{
						value: nameEn,
						setter: setNameEn,
						type: 'text',
						usageType: 'textfield',
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: 'en',
						permissionModel: 'name',
					},
					{
						value: nameRu,
						setter: setNameRu,
						type: 'text',
						usageType: 'textfield',
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: 'ru',
						permissionModel: 'name',
					},
					{
						value: nameHy,
						setter: setNameHy,
						type: 'text',
						usageType: 'textfield',
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: 'hy',
						permissionModel: 'name',
					},
					{
						value: descEn,
						setter: setDescEn,
						type: 'text',
						usageType: 'textfield',
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: 'en',
						permissionModel: 'desc',
					},
					{
						value: descRu,
						setter: setDescRu,
						type: 'text',
						usageType: 'textfield',
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: 'ru',
						permissionModel: 'desc',
					},
					{
						value: descHy,
						setter: setDescHy,
						type: 'text',
						usageType: 'textfield',
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: 'hy',
						permissionModel: 'desc',
					},
					{
						value: updatedAt,
						setter: setUpdatedAt,
						type: 'text',
						usageType: 'textfield',
						label: config.translate.updatedAt[lang],
						placeholder: config.translate.updatedAt[lang],
						disabled: true,
						permissionModel: 'updatedAt',
					},
				]}
				permissionsTableComponent={{
					title: 'Permissions',
					data:
						permissions && permissions.length > 0
							? permissions
							: [],
					columns,
					setter: setPermissions,
					permissionModel: 'permissions',
				}}
			/>
		);
}
