import React, { useEffect, useState } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import * as API from "../../helpers/api";
import NProgress from "nprogress";
import { useLang } from "../../helpers/language";
import moment from "moment";
import { useMantineColorScheme } from "@mantine/core";

export default function DebtList() {
	const { lang } = useLang();
	const { colorScheme } = useMantineColorScheme();
	const [clients, setClients] = useState([]);
	const [orders, setOrders] = useState([]);
	const [isBusy, setBusy] = useState(true);
	const [columns, setColumns] = useState([]);

	function setRefreshColumns() {
		return [
			{
				accessorKey: "ID",
				permissionModel: "ID",
				header: "ID",
				size: 200,
				enableClickToCopy: true,
			},
			{
				accessorFn: (row) => String(row.order?.ID),
				permissionModel: "order",
				header: config.translate.order[lang],
				filterVariant: "select",
				mantineFilterSelectProps: {
					data: orders.map((order) => ({
						value: order._id,
						label: String(order.ID),
					})),
				},
				size: 300,
				columnSizing: true,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row.order?.ID;
				},
			},
			{
				accessorKey: "amount",
				permissionModel: "amount",
				header: config.translate.price[lang],
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row.amount;
				},
			},
			{
				accessorFn: (row) => row.client?.name,
				permissionModel: "client",
				header: config.translate.client[lang],
				filterVariant: "select",
				mantineFilterSelectProps: {
					data: clients.map((client) => ({
						value: client._id,
						label: client.name,
					})),
				},
				size: 300,
				columnSizing: true,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row.client?.name;
				},
			},
			{
				accessorKey: "date",
				permissionModel: "date",
				header: config.translate.date[lang],
				enableClickToCopy: true,
				filterVariant: "date-range",
				size: 320,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					let result = moment(row.date).format("DD-MM-YYYY");
					return result;
				},
			},
		];
	}

	useEffect(() => {
		NProgress.start();
		(async () => {
			let rawClients = await API.get(config.api.client);
			if (rawClients) setClients(rawClients);
			let rawOrders = await API.get(config.api.client);
			if (rawOrders) setOrders(rawOrders);

			setColumns(setRefreshColumns());

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		setColumns(setRefreshColumns());
		// eslint-disable-next-line
	}, [lang, colorScheme]);

	if (!isBusy && columns.length > 0)
		return (
			<ListPage
				route={config.api.debt}
				query={{}}
				sorting={{ index: "asc" }}
				permissionModel={"debt"}
				uploadType={null}
				limit={null}
				customFiltersArrays={{
					clients: clients,
					orders: orders,
				}}
				populate={["client", "order"]}
				columns={columns}
				pageSize={100}
			/>
		);
}
