import React, { useMemo, useEffect, useState } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import * as API from "../../helpers/api";
import NProgress, { set } from "nprogress";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import Loading from "../modules/loading";
import moment from "moment";
import { Avatar, Box, Button, filterProps, Flex, Menu, Text, Title } from "@mantine/core";
import { Link, useParams } from "react-router-dom";
import { Image, useMantineColorScheme } from "@mantine/core";

const customColors = {
	titleColor: "#2ea7c1",
	textColor: "#666",
};

export default function ClientAnalyticsList() {
	const { user } = useUser();
	const { lang } = useLang();
	let { userWorkingStatus } = useParams();
	const { colorScheme } = useMantineColorScheme();
	const [clients, setClients] = useState([]);
	const [orders, setOrders] = useState([]);
	const [isBusy, setBusy] = useState(true);
	const [columns, setColumns] = useState([]);
	const [clientPrice, setClientPrice] = useState(0);
	const [clientPaid, setClientPaid] = useState(0);
	const [clientDebt, setClientDebt] = useState(0);

	function setRefreshColumns() {
		return [
			{
				accessorFn: (row) => row.client?.name,
				permissionModel: "client",
				header: config.translate.client[lang],
				filterVariant: "select",
				mantineFilterSelectProps: {
					data: clients.map((client) => ({
						value: client._id,
						label: client.name,
					})),
				},
				size: 300,
				columnSizing: true,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row.client.name;
				},
			},
			{
				accessorKey: "ID",
				permissionModel: "ID",
				header: config.translate.order[lang],
				size: 100,
				enableClickToCopy: true,
			},
			{
				accessorKey: "price",
				permissionModel: "price",
				header: config.translate.price[lang],
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row.price;
				},
			},
			{
				accessorKey: "paid",
				permissionModel: "paid",
				header: config.translate.paid[lang],
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row.paid;
				},
			},
			{
				accessorKey: "debt",
				permissionModel: "debt",
				header: config.translate.debt[lang],
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row.debt;
				},
			},
			{
				accessorKey: "date",
				permissionModel: "date",
				header: config.translate.date[lang],
				enableClickToCopy: true,
				filterVariant: "date-range",
				size: 320,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					let result = moment(row.date).format("MM.DD.YYYY");
					return result;
				},
			},
			// {
			// 	accessorKey: "date",
			// 	permissionModel: "date",
			// 	header: config.translate.date[lang],
			// 	enableClickToCopy: true,
			// 	filterVariant: "range",
			// 	filterFn: "between",
			// 	size: 300,
			// 	Cell: (
			// 		RecievedObject,
			// 		cell = RecievedObject.renderedCellValue,
			// 		row = RecievedObject.row.original
			// 	) => {
			// 		let result = moment(row?.date).format("DD-MM-YYYY");
			// 		return result;
			// 	},
			// },
		];
	}

	useEffect(() => {
		NProgress.start();
		(async () => {
			let rawClients = await API.get(config.api.client);
			if (rawClients) setClients(rawClients);

			setColumns(setRefreshColumns());

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		setColumns(setRefreshColumns());
	}, [lang, colorScheme]);

	if (!isBusy && columns.length > 0)
		return (
			<ListPage
				route={config.api.order}
				query={{}}
				sorting={{ date: "asc" }}
				permissionModel={"order"}
				uploadType={null}
				limit={null}
				customFiltersArrays={{
					clients: clients,
				}}
				header={[
					{
						title: config.translate.price[lang],
						value: clientPrice,
						setter: setClientPrice,
						color: "blue.6",
					},
					{
						title: config.translate.paid[lang],
						value: clientPaid,
						setter: setClientPaid,
						color: "teal.6",
					},
					{
						title: config.translate.debt[lang],
						value: clientDebt,
						setter: setClientDebt,
						color: "red.6",
					},
				]}
				noAdd={true}
				populate={["client"]}
				columns={columns}
				clientAnalytics={true}
				pageSize={100}
			/>
		);
}
