import React, { useState, useEffect, useMemo } from 'react';
import SinglePage from '../SinglePage';
import { useParams } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loading';
import { useUser } from '../../helpers/userContext';
import moment from 'moment';
import parse from 'html-react-parser';
import { useLang } from '../../helpers/language';
import NProgress from 'nprogress';

export default function Encashment() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.encashment;
	const { lang } = useLang();
	const { user } = useUser();
	const [permissions, setPermissions] = useState([]);

	const [ID, setID] = useState('');

	const [price, setPrice] = useState(0);
	const [date, setDate] = useState(new Date());
	const [desc, setDesc] = useState('');

	const modelSendToServer = {
		ID,
		price,
		user: user._id,
		date,
		desc,
	};

	useEffect(() => {
		(async () => {
			NProgress.start();

			let rawID = [];
			try {
				rawID = await API.get(
					route,
					{ temprorary: { $ne: true } },
					{ ID: 'desc' },
					{},
					1
				);
			} catch (error) {
				console.log(error);
			}

			if (id !== 'add') {
				let currentItem = await API.get(route, {
					_id: id,
				});

				setID(
					currentItem[0]?.ID
						? currentItem[0]?.ID
						: rawID[0]?.ID
						? rawID[0]?.ID + 1
						: 1
				);
				currentItem[0]?.date && setDate(new Date(currentItem[0]?.date));
				setPrice(currentItem[0]?.price);
				setDesc(currentItem[0]?.desc);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === 'user') {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				permissions={permissions}
				permissionModel='encashment'
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: ID,
						setter: setID,
						type: 'number',
						usageType: 'number',
						label: 'ID',
						permissionModel: 'ID',
					},
					{
						value: user.name,
						// setter: setUser,
						disabled: true,
						type: 'textfield',
						usageType: 'textfield',
						label: config.translate.user[lang],
						placeholder: config.translate.user[lang],
						permissionModel: 'user',
					},
					{
						value: price,
						setter: setPrice,
						type: 'number',
						usageType: 'number',
						label: config.translate.price[lang],
						placeholder: config.translate.price[lang],
						permissionModel: 'price',
					},
					{
						value: date,
						setter: setDate,
						type: 'datePicker',
						usageType: 'datePicker',
						label: config.translate.date[lang],
						placeholder: config.translate.date[lang],
						permissionModel: 'date',
					},
					{
						value: desc,
						setter: setDesc,
						type: 'textarea',
						usageType: 'textarea',
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						permissionModel: 'desc',
					},
				]}
			/>
		);
}
