import React, { useEffect, useState } from "react";
import config from "../../config";
import * as API from "../../helpers/api";
import NProgress from "nprogress";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import {
	Autocomplete,
	Card,
	Divider,
	Flex,
	Grid,
	Group,
	Paper,
	Text,
	CloseIcon,
	Button,
	Menu,
} from "@mantine/core";
import { AreaChart } from "@mantine/charts";
import { DatePicker } from "@mantine/dates";
import moment from "moment";
import Swal from "sweetalert2";
import {
	MantineReactTable,
	useMantineReactTable,
	MRT_ToggleFiltersButton,
} from "mantine-react-table";
import { Link } from "react-router-dom";

export default function ProductAnalyticsList() {
	const { lang } = useLang();
	const [products, setProducts] = useState([]);
	const [isBusy, setBusy] = useState(true);
	const [weight, setWeight] = useState(0);
	const [selectedProduct, setSelectedProduct] = useState(null);

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const [allOrders, setAllOrders] = useState([]);

	const [howManyKGSelledProduct, setHowManyTimesSelledProduct] = useState(0);
	const [howManyKGEntryProduct, setHowManyKGEntryProduct] = useState(0);
	const [howManyKGRemainProduct, setHowManyKGRemainProduct] = useState(0);

	const [productAnalytics, setProductAnalytics] = useState([
		{
			product: "",
			entry: 0,
			selled: 0,
			remains: 0,
			date: "",
		},
	]);
	const [totalProductAnalytics, setTotalProductAnalytics] = useState([]);

	const columns = [
		{
			accessorKey: "ID",
			permissionModel: "ID",
			header: "ID",
			size: 100,
			enableClickToCopy: true,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				let result = row.product ? row.product : 0;
				return String(result);
			},
		},
		{
			accessorKey: "price",
			permissionModel: "price",
			header: config.translate.price[lang],
			filterVariant: "range",
			filterFn: "between",
			size: 200,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return row.price;
			},
		},
		{
			accessorKey: "weight",
			permissionModel: "weight",
			header: config.translate.weight[lang],
			filterVariant: "range",
			filterFn: "between",
			size: 200,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return row.weight;
			},
		},
		{
			accessorKey: "quantity",
			permissionModel: "quantity",
			header: config.translate.quantity[lang],
			filterVariant: "range",
			filterFn: "between",
			size: 200,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return row.quantity;
			},
		},
		{
			accessorKey: "diameter",
			permissionModel: "diameter",
			header: config.translate.diameter[lang],
			size: 200,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return row.diameter;
			},
		},
	];

	const table = useMantineReactTable({
		columns: columns ? columns : [],
		layoutMode: "grid-no-grow",
		columnResizeMode: "onChange",
		data: productAnalytics ? productAnalytics : [],
		columnVirtualizerProps: {
			overscan: 5, //adjust the number of columns that are rendered to the left and right of the visible area of the table
			estimateSize: () => 40, //if your columns are wider or , try tweaking this value to make scrollbar size more accurate
		},
		rowVirtualizerProps: {
			overscan: 10, //adjust the number or rows that are rendered above and below the visible area of the table
			estimateSize: () => 10, //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
		},
		manualPagination: true,
		manualFiltering: true,
		manualSorting: false,
		renderTopToolbar: ({ table }) => {
			return (
				<Flex p="md" justify="space-between">
					<Flex gap="xs">
						{totalProductAnalytics.length > 0 &&
							totalProductAnalytics.map((product) => {
								return (
									<Card>
										<Text>
											{config.translate.product[lang]} {product.product}
										</Text>
										<Text c={"red.6"}>
											{new Intl.NumberFormat("en-US").format(product.weight)}
										</Text>
									</Card>
								);
							})}
					</Flex>
				</Flex>
			);
		},
	});

	function ChartTooltip({ label, payload }) {
		if (!payload) return null;
		return (
			<Paper px="md" py="sm" withBorder shadow="md" radius="md">
				<Text fw={500} mb={5}>
					{/* if label is date then moment it */}
					{moment(label).format("MMM D, YYYY")}
				</Text>
				{payload.map((item) => (
					<Text key={item.name} c={item.color} fz="sm">
						{config.translate?.[item.name]?.[lang]}: {item.value}
					</Text>
				))}
			</Paper>
		);
	}

	useEffect(() => {
		NProgress.start();
		(async () => {
			let rawProducts = await API.get(config.api.product);
			if (rawProducts) setProducts(rawProducts);

			let rawAllOrders = await API.get(config.api.order);
			setAllOrders(rawAllOrders);

			setWeight(weight);

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		// selectedProduct relaterd data of orders when this product is selected
		(async () => {
			let rawOrders = await API.get(
				config.api.order,
				{
					date: {
						$gte: startDate.toLocaleString().split(",")[0],
						$lte: endDate.toLocaleString().split(",")[0],
					},
				},
				null,
				null,
				null,
				["cart.product"]
			);
			let productAnalytics = [];

			if (selectedProduct) {
				let selectedProductOrders = rawOrders.filter((order) => {
					return order.cart.find((cartItem) => cartItem.product === selectedProduct._id);
				});
				let selectedProductOrdersData = selectedProductOrders.map((order) => {
					let cartItem = order.cart.find(
						(cartItem) => cartItem.product === selectedProduct._id
					);
					// find in cart item product and add to selled
					productAnalytics.push({
						product: selectedProduct.ID,
						selled: cartItem.weight,
						entry: 0,
						date: order.date.toLocaleString().split(",")[0],
					});

					return {
						...order,
						...cartItem,
					};
				});

				// how many times product is sold
				let howManyKGSelledProduct = 0;
				selectedProductOrdersData.forEach((order) => {
					howManyKGSelledProduct += order.cart.find(
						(cartItem) => cartItem.product === selectedProduct._id
					).weight;
				});
				setHowManyTimesSelledProduct(howManyKGSelledProduct);

				// how many times product is remain find and set productAnalytics array to show in chart
				let productRemain = selectedProduct.weight - howManyKGSelledProduct;
				setHowManyKGRemainProduct(productRemain);

				// how many times product is entered
				let howManyKGEntryProduct = 0;
				let productEntries = await API.get(config.api.productEntry, {
					date: {
						$gte: startDate,
						$lte: endDate,
					},
				});
				productEntries.forEach((entry) => {
					if (entry.product === selectedProduct._id) {
						productAnalytics.push({
							product: selectedProduct.ID,
							entry: entry.weight,
							selled: 0,
							date: entry.date.toLocaleString().split(",")[0],
						});
						howManyKGEntryProduct += entry.weight;
					}
				});
				setHowManyKGEntryProduct(howManyKGEntryProduct);

				// filter productAnalytics array to show in chart
				selectedProductOrdersData.forEach((order) => {
					let cartItem = order.cart.find(
						(cartItem) => cartItem.product === selectedProduct._id
					);
					productAnalytics.push({
						product: selectedProduct.ID,
						selled: cartItem.weight,
						entry: 0,
						date: order.date.toLocaleString().split(",")[0],
					});
				});
				// product analytics loop in it and find dublictaes by dsate and combine them
				productAnalytics = productAnalytics.reduce((acc, current) => {
					const x = acc.find((item) => item.date === current.date);
					if (x) {
						return acc.map((item) =>
							item.date === current.date
								? {
										...item,
										entry: item.entry + current.entry,
										selled: item.selled + current.selled,
										remains: item.remains + current.remains,
									}
								: item
						);
					} else {
						return [...acc, current];
					}
				}, []);

				//  product analytics loop in it reorder by date
				productAnalytics.sort((a, b) => {
					return new Date(a.date) - new Date(b.date);
				});

				// find and set productAnalytics array remainning product matter of date, and remember last value to add or substract, use both fields "entry" and "selled"
				let lastRemain = 0;
				productAnalytics.reduce((acc, current) => {
					current.remains = lastRemain + current.entry - current.selled;
					lastRemain = current.remains;
					return current;
				}, 0);

				setProductAnalytics(productAnalytics);
			} else {
				// if no product selected show all products with selected date range

				let productOrders = await API.get(
					config.api.order,
					{
						date: {
							$gte: startDate,
							$lte: endDate,
						},
					},
					null,
					null,
					null,
					["cart.product"]
				);
				productOrders.forEach((order) => {
					order.cart.forEach((cartItem) => {
						productAnalytics.push({
							...cartItem,
							taxCode: cartItem.product.taxCode,
							date: order.date.toLocaleString().split(",")[0],
							product: cartItem.product.ID,
						});
					});
				});
				// find and combine all products by ID and create u nuique array of products with total selled weight
				let totalProductAnalytics = productAnalytics.reduce((acc, current) => {
					const x = acc.find((item) => item.product === current.product);
					if (x) {
						return acc.map((item) =>
							item.product === current.product
								? {
										...item,
										weight: item.weight + current.weight,
									}
								: item
						);
					} else {
						return [...acc, current];
					}
				}, []);

				setTotalProductAnalytics(totalProductAnalytics);
				setProductAnalytics(productAnalytics);
			}
			NProgress.done();
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedProduct, startDate, endDate]);

	if (!isBusy) {
		if (startDate && endDate) {
			if (startDate > endDate) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Start date can't be greater than end date",
				});
				setStartDate(endDate);
			}

			return (
				<>
					<Paper withBorder p="md" radius="md" shadow="xs">
						<Grid gutter="md" columns={12} justify="center" align="center">
							<Grid.Col span={3}>
								<Group justify="center">
									<Text size="xl" weight={700} w={"100%"} align="center">
										{config.translate.startDate[lang]}
									</Text>
									<DatePicker
										value={startDate}
										onChange={(date) => {
											//  dahealt date is GMT=4 change it to MGMT=0
											date.setHours(0);
											setStartDate(date);
										}}
										valueFormat="DD/MM/YYYY"
										firstDayOfWeek={6}
									/>
								</Group>
							</Grid.Col>
							<Grid.Col span={6}>
								<Text size="xl" weight={700} align="center" w={"100%"}>
									{config.translate.product[lang]}
								</Text>
								<Autocomplete
									label={config.translate.selectProduct[lang]}
									placeholder={config.translate.selectProduct[lang]}
									mb={40}
									data={products.flatMap((product) => {
										return {
											value: product._id,
											label: String(product.ID),
										};
									})}
									value={selectedProduct?.ID || ""}
									onChange={(productLabel) => {
										setSelectedProduct(
											products.find(
												(product) => product.ID === Number(productLabel)
											)
										);
									}}
								/>
								{selectedProduct && (
									<Flex
										justify={"center"}
										direction={"column"}
										align="center"
										w={"100%"}
										gap={20}
									>
										<Text
											size="xl"
											mb={10}
											weight={700}
											align="center"
											w={"100%"}
										>
											{config.translate.product[lang]} {selectedProduct.ID}
										</Text>
										{selectedProduct?.desc && (
											<Text
												size="xs"
												weight={300}
												mb={10}
												align="center"
												w={"100%"}
											>
												{parse(selectedProduct.desc || "")}
											</Text>
										)}
										<Flex align="center" w={"100%"} gap={20}>
											<Card>
												<Text>{config.translate.price[lang]}</Text>
												<Text c={"blue.6"}>
													{new Intl.NumberFormat("en-US").format(
														selectedProduct?.price
													)}
												</Text>
											</Card>
											<Card>
												<Text>{config.translate.weight[lang]}</Text>
												<Text c={"blue.6"}>
													{new Intl.NumberFormat("en-US").format(
														selectedProduct?.weight
													)}
												</Text>
											</Card>
											<Card>
												<Text>{config.translate.diameter[lang]}</Text>
												<Text c={"blue.6"}>
													{selectedProduct?.diameter}
												</Text>
											</Card>
											<Card>
												<Text>{config.translate.quantity[lang]}</Text>
												<Text c={"blue.6"}>
													{new Intl.NumberFormat("en-US").format(
														selectedProduct?.quantity
													)}
												</Text>
											</Card>
										</Flex>
									</Flex>
								)}
							</Grid.Col>
							<Grid.Col span={3}>
								<Group justify="center">
									<Text size="xl" weight={700} w={"100%"} align="center">
										{config.translate.endDate[lang]}
									</Text>
									<DatePicker
										value={endDate}
										onChange={(date) => {
											let dateEnd = new Date(date);
											dateEnd.setHours(0);
											setEndDate(dateEnd);
										}}
										valueFormat="DD/MM/YYYY"
										firstDayOfWeek={6}
									/>
								</Group>
							</Grid.Col>
							<Grid.Col span={12}>
								<Divider my="lg" />
								<Flex justify={"center"} align="center" w={"100%"} gap={20}>
									{selectedProduct && (
										<>
											<Card>
												<Text>{config.translate.remain[lang]}</Text>
												<Text c={"red.6"}>
													{new Intl.NumberFormat("en-US").format(
														howManyKGRemainProduct
													)}
												</Text>
											</Card>
											<Card>
												<Text>{config.translate.productEntry[lang]}</Text>
												<Text c={"blue.6"}>
													{new Intl.NumberFormat("en-US").format(
														howManyKGEntryProduct
													)}
												</Text>
											</Card>
											<Card>
												<Text>{config.translate.selledInCarats[lang]}</Text>
												<Text c={"orange.6"}>
													{new Intl.NumberFormat("en-US").format(
														howManyKGSelledProduct
													)}
												</Text>
											</Card>
											<Card>
												<Text>{config.translate.income[lang]}</Text>
												<Text c={"cyan.6"}>
													{new Intl.NumberFormat("en-US").format(
														howManyKGSelledProduct *
															selectedProduct.price
													)}
												</Text>
											</Card>
										</>
									)}
								</Flex>
							</Grid.Col>
							<Divider my="lg" />
							<Grid.Col span={12}>
								{selectedProduct ? (
									<AreaChart
										h={300}
										data={productAnalytics}
										dataKey="date"
										series={[
											{ name: "remains", color: "red.6" },
											{ name: "entry", color: "blue.6" },
											{ name: "selled", color: "orange.6" },
										]}
										tooltipProps={{
											content: ({ label, payload }) => (
												<ChartTooltip label={label} payload={payload} />
											),
										}}
										curveType="linear"
										withBarValueLabel
										valueFormatter={(value) =>
											new Intl.NumberFormat("en-US").format(value)
										}
									/>
								) : (
									<MantineReactTable table={table} />
								)}
							</Grid.Col>
						</Grid>
					</Paper>
				</>
			);
		}
	}
}
