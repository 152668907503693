import React from "react";
import { ListPage } from "../ListPage";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import config from "../../config";

export default function ProductList() {
	const { lang } = useLang();
	const columns = [
		{
			accessorKey: "ID",
			permissionModel: "ID",
			header: "ID",
			size: 100,
			enableClickToCopy: true,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				let result = row.ID ? row.ID : 0;
				return String(result);
			},
		},
		{
			accessorKey: "price",
			permissionModel: "price",
			header: config.translate.price[lang],
			filterVariant: "range",
			filterFn: "between",
			size: 200,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return row.price;
			},
		},
		{
			accessorKey: "weight",
			permissionModel: "weight",
			header: config.translate.weight[lang],
			filterVariant: "range",
			filterFn: "between",
			size: 200,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return row.weight;
			},
		},
		{
			accessorKey: "desc",
			permissionModel: "desc",
			header: config.translate.desc[lang],
			size: 200,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				let result = row.desc ? row.desc : "...";
				return parse(result.length > 20 ? result.slice(0, 20) + "..." : result);
			},
		},
		{
			accessorKey: "quantity",
			permissionModel: "quantity",
			header: config.translate.quantity[lang],
			filterVariant: "range",
			filterFn: "between",
			size: 200,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return row.quantity;
			},
		},
		{
			accessorKey: "diameter",
			permissionModel: "diameter",
			header: config.translate.diameter[lang],
			size: 200,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return row.diameter;
			},
		},
		{
			accessorKey: "taxCode",
			permissionModel: "taxCode",
			header: config.translate.taxCode[lang],
			size: 200,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return row.taxCode;
			},
		},
	];
	return (
		<ListPage
			route={config.api.product}
			query={{}}
			sorting={{ index: "asc" }}
			permissionModel={"product"}
			uploadType={null}
			limit={null}
			columns={columns}
			pageSize={100}
		/>
	);
}
