import React, { useEffect, useState } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import * as API from "../../helpers/api";
import NProgress from "nprogress";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import moment from "moment";
import { useMantineColorScheme } from "@mantine/core";

export default function EncashmentList() {
	const { lang } = useLang();
	const { colorScheme } = useMantineColorScheme();
	const [users, setUsers] = useState([]);
	const [isBusy, setBusy] = useState(true);
	const [columns, setColumns] = useState([]);
	const [total, setTotal] = useState(0);

	function setRefreshColumns() {
		return [
			{
				accessorKey: "ID",
				permissionModel: "ID",
				header: "ID",
				size: 200,
				enableClickToCopy: true,
			},
			{
				accessorKey: "price",
				permissionModel: "price",
				header: config.translate.price[lang],
				filterVariant: "range",
				filterFn: "between",
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row.price;
				},
			},
			{
				accessorFn: (row) => row.user?.name,
				permissionModel: "user",
				header: config.translate.user[lang],
				filterVariant: "select",
				mantineFilterSelectProps: {
					data: users.map((user) => ({
						value: user._id,
						label: user.name,
					})),
				},
				size: 300,
				columnSizing: true,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row.user?.name;
				},
			},
			{
				accessorKey: "date",
				permissionModel: "date",
				header: config.translate.date[lang],
				enableClickToCopy: true,
				filterVariant: "date",
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					let result = moment(row.date).format("DD-MM-YYYY");
					return result;
				},
			},
			{
				accessorKey: "desc",
				permissionModel: "desc",
				header: config.translate.desc[lang],
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					let result = row.desc ? row.desc : "...";
					return parse(result.length > 20 ? result.slice(0, 20) + "..." : result);
				},
			},
		];
	}

	useEffect(() => {
		NProgress.start();
		(async () => {
			let rawClients = await API.get(config.api.user);
			if (rawClients) setUsers(rawClients);

			let total = await API.get(config.api.total);
			if (total) setTotal(total[0]);

			setColumns(setRefreshColumns());

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		setColumns(setRefreshColumns());
		// eslint-disable-next-line
	}, [lang, colorScheme]);

	if (!isBusy && columns.length > 0)
		return (
			<ListPage
				route={config.api.encashment}
				query={{}}
				sorting={{ index: "asc" }}
				permissionModel={"encashment"}
				uploadType={null}
				limit={null}
				customFiltersArrays={{
					users: users,
				}}
				populate={["user"]}
				columns={columns}
				header={[
					{
						title: config.translate.total[lang],
						value: total.price,
					},
					{
						title: config.translate.paid[lang],
						value: total.paid,
					},
					{
						title: config.translate.debt[lang],
						value: total.debt,
					},
				]}
				pageSize={100}
			/>
		);
}
