import React, { useState, useEffect, useRef } from "react";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useUser } from "../../helpers/userContext";
import moment from "moment";
import parse from "html-react-parser";
import { useLang } from "../../helpers/language";
import NProgress, { set } from "nprogress";
import { IconCalendar, IconPlus, IconTrash } from "@tabler/icons-react";
import { useParams, useHistory } from "react-router-dom";
import { UPDATE } from "../../helpers/CRUD";
import { useReactToPrint } from "react-to-print";
import { DateInput, DateInputProps } from "@mantine/dates";

import {
	Autocomplete,
	Badge,
	Button,
	Card,
	Checkbox,
	Divider,
	Flex,
	Grid,
	Group,
	Image,
	Input,
	NumberInput,
	Paper,
	Select,
	Stepper,
	Table,
	Text,
	Title,
} from "@mantine/core";
import Swal from "sweetalert2";

export default function Order() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.order;
	const { lang } = useLang();
	const { user } = useUser();
	const [permissions, setPermissions] = useState([]);
	const history = useHistory();

	const [ID, setID] = useState("");
	const [client, setClient] = useState("");
	const [price, setPrice] = useState(0);
	const [paid, setPaid] = useState(0);
	const [debt, setDebt] = useState(0);
	const [status, setStatus] = useState("");
	const [desc, setDesc] = useState("");
	const [date, setDate] = useState(new Date());
	const [cart, setCart] = useState([]);
	const [productAutocompleteValue, setProductAutocompleteValue] = useState("");

	//selected values
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedWeight, setSelectedWeight] = useState(null);
	const [selectedDiameter, setSelectedDiameter] = useState(null);
	const [selectedQuantity, setSelectedQuantity] = useState(null);

	const [selectedRows, setSelectedRows] = useState([]);

	let rows = cart?.map(({ product, weight, diameter, price, quantity }) => {
		price = Math.round(price);
		weight = Number(weight)?.toFixed(3);
		return (
			<Table.Tr
				key={product.ID}
				bg={
					selectedRows.includes(product.ID)
						? "var(--mantine-color-blue-light)"
						: undefined
				}
			>
				<Table.Td>
					<Checkbox
						aria-label="Select row"
						checked={selectedRows.includes(product.ID)}
						onChange={(event) =>
							setSelectedRows(
								event.currentTarget.checked
									? [...selectedRows, product.ID]
									: selectedRows.filter((product) => product.ID !== product.ID)
							)
						}
					/>
				</Table.Td>
				<Table.Td>{product.ID}</Table.Td>
				<Table.Td>{weight}</Table.Td>
				<Table.Td>{diameter}</Table.Td>
				<Table.Td>{quantity}</Table.Td>
				<Table.Td>
					<NumberInput
						label={config.translate.price[lang]}
						w={100}
						placeholder={config.translate.price[lang]}
						value={cart.filter((item) => item.product.ID === product.ID)[0].price}
						onChange={(newPrice) => {
							let newCart = cart.map((item) => {
								if (item.product.ID === product.ID) {
									return {
										...item,
										price: newPrice,
										weight: Number(item.weight).toFixed(3),
									};
								}
								return item;
							});
							setCart(newCart);
							setPrice(newCart.reduce((acc, { price }) => acc + price, 0));
						}}
					/>
				</Table.Td>
			</Table.Tr>
		);
	});

	// use for selects
	const [allProducts, setAllProducts] = useState([]);
	const [allClients, setAllClients] = useState([]);

	const [selectedProdcutPrice, setSelectedProductPrice] = useState(0);

	const componentRef = useRef();
	const componentRef2 = useRef();

	const handlePrint = () => {
		const printContent = componentRef.current;
		const newWindow = window.open("", "", "width=480,height=280");

		newWindow.document.write(`
		  <html>
		<head>
			<title>Print Badge</title>
			<style>
				* {
					margin: 0;
					padding: 0;
					box-sizing: border-box;
				}

				body {
					font-family: Arial, sans-serif;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #f8f8f8;
					flex-direction: column;
					height: fit-content;
					width: 400px;
					border: 1px solid black;
					border-radius: 5px;
					margin: 10px;
					padding: 10px;
				}

				.badge {
					width: 300px;
					height: 300px;
					background-color: white;
					border: 2px solid #ccc;
					border-radius: 10px;
					box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					padding: 20px;
				}

				.header, .footer {
					text-align: center;
					font-size: 18px;
					font-weight: bold;
					display: flex;
					justify-content: space-between;
					width: 100%;
					padding: 10px;
					background-color: #f0f0f0;
					border-radius: 5px;
					}
					
				.content { 	
					text-align: center;
					font-size: 16px;
					width: 100%;
					padding: 10px;
				}

				.product-table {
					width: 100%;
				}

				.product-table th, .product-table td {
					background-color: #f0f0f0;
					padding: 10px;
					border-radius: 5px;
					text-align: left;
				}

			</style>
        </head>
			<body>${printContent?.innerHTML}</body>
		  </html>
		`);
		newWindow.document.close();

		// Give time for the new window to render before printing
		setTimeout(() => {
			newWindow.focus();
			newWindow.print();
			newWindow.close();
		}, 500); // 500ms delay for rendering
	};

	const modelSendToServer = {
		ID,
		client: client?._id,
		cart,
		price,
		status,
		desc,
		debt,
		paid,
		date,
	};

	const addToCart = ({ product, weight, diameter, price, quantity }) => {
		if (cart?.find((item) => item.product.ID === product.ID)) {
			let newCart = cart.map((item) => {
				if (item.product.ID === product.ID) {
					return {
						...item,
						weight: (Number(item?.weight) + Number(weight))?.toFixed(3),
						quanitity: item?.quantity + quantity,
						price: price + product.price * weight,
					};
				}
				return item;
			});
			setCart(newCart);
			setPrice(newCart.reduce((acc, { price }) => acc + price, 0));
			return;
		} else {
			setCart([
				...cart,
				{
					product,
					weight,
					diameter,
					quantity,
					price: price * weight,
				},
			]);
			const updatedCart = [
				...cart,
				{
					product,
					weight,
					diameter,
					quantity,
					price: price * weight,
				},
			];
			setCart(updatedCart);
			setPrice(updatedCart.reduce((acc, { price }) => acc + price, 0));
		}
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			// all users
			let rawclients = await API.get(config.api.client, {
				temprorary: { $ne: true },
			});
			setAllClients(rawclients);
			// all products
			let rawProducts = await API.get(config.api.product, {
				temprorary: { $ne: true },
			});
			setAllProducts(rawProducts);

			let rawID = await API.get(route, { temprorary: { $ne: true } }, { ID: "desc" }, {}, 1);

			if (id !== "add") {
				let currentItem = await API.get(
					route,
					{
						_id: id,
					},
					null,
					null,
					null,
					["client", "cart.product"]
				);

				setID(
					currentItem[0]?.ID ? currentItem[0]?.ID : rawID[0]?.ID ? rawID[0]?.ID + 1 : 1
				);
				setCart(
					currentItem[0]?.cart && Array.isArray(currentItem[0]?.cart)
						? currentItem[0]?.cart
						: []
				);
				currentItem[0]?.date && setDate(new Date(currentItem[0]?.date));
				setClient(currentItem[0]?.client);
				setPrice(currentItem[0]?.price);
				setStatus(currentItem[0]?.status);
				setDesc(currentItem[0]?.desc);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "order") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	useEffect(() => {
		let tempPrice = cart.reduce((acc, { price }) => acc + price, 0);
		tempPrice = Math.round(tempPrice);
		setPrice(tempPrice);
		setDebt(tempPrice - paid);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [price, paid, cart, cart.length]);

	useEffect(() => {}, [selectedRows, rows, paid, selectedProduct]);

	if (isBusy) return <Loading />;
	else
		return (
			<>
				<Paper withBorder p="md" radius="md" shadow="xs">
					<Grid gutter="md" columns={12} justify="center" align="center">
						<Grid.Col
							span={12}
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<Card w={"100%"} mr={10}>
								<Text
									weight={700}
									style={{
										textAlign: "center",
									}}
								>
									{config.translate.order[lang]} {ID}
								</Text>

								<Autocomplete
									data={allClients.flatMap((client) => {
										return {
											value: client._id,
											label: client.name,
										};
									})}
									onChange={(val) => {
										let client = allClients.find(
											(client) => client.name === val
										);
										setClient(client);
									}}
									value={client?.name}
									label={config.translate.client[lang]}
									placeholder={config.translate.client[lang]}
								/>
							</Card>
							<Card w={"100%"} ml={10}>
								<Text
									weight={700}
									style={{
										textAlign: "center",
									}}
								>
									{config.translate.date[lang]}
								</Text>

								<DateInput
									value={date}
									onChange={(date) => setDate(date)}
									valueFormat="DD/MM/YYYY"
									label={config.translate.date[lang]}
									placeholder={config.translate.date[lang]}
									leftSection={<IconCalendar size={16} />}
								/>
							</Card>
						</Grid.Col>
						<Divider my="lg" />
						<Grid.Col span={12}>
							<Flex gap={20} align={"end"}>
								<Button
									disabled={selectedRows.length === 0}
									color="red"
									onClick={() => {
										setCart(
											cart.filter(
												({ product }) => !selectedRows.includes(product.ID)
											)
										);
										setSelectedRows([]);
									}}
								>
									<IconTrash size={20} />
								</Button>

								{/* hidden component for printing */}
								<div
									ref={componentRef}
									class="badge"
									style={{
										display: "none",
									}}
								>
									<div className="header">
										<span>
											{config.translate.order[lang]} ID: {ID}
										</span>
									</div>
									<div className="content">
										<Table className="product-table">
											<Table.Thead>
												<Table.Tr>
													<Table.Th>
														{config.translate.product[lang]}
													</Table.Th>
													<Table.Th>
														{config.translate.weight[lang]}
													</Table.Th>
													<Table.Th>
														{config.translate.size[lang]}
													</Table.Th>
													<Table.Th>
														{config.translate.quantity[lang]}
													</Table.Th>
													<Table.Th>
														{config.translate.price[lang]}
													</Table.Th>
												</Table.Tr>
											</Table.Thead>
											{cart.map(({ product, weight, diameter, quantity }) => (
												<Table.Tr key={product.ID}>
													<Table.Td className="product">
														{product.ID}
													</Table.Td>
													<Table.Td className="weight">{weight}</Table.Td>
													<Table.Td className="diameter">
														{diameter}
													</Table.Td>
													<Table.Td className="quantity">
														{quantity}
													</Table.Td>
													<Table.Td>{product.price * weight}</Table.Td>
												</Table.Tr>
											))}
										</Table>
									</div>
									<div className="footer">
										<span>
											{config.translate.date[lang]}:{" "}
											{moment(date).format("DD/MM/YYYY")}
										</span>
										<span>
											{config.translate.total[lang]}:{" "}
											{cart.reduce((acc, { price }) => acc + price, 0)}
										</span>
									</div>
								</div>

								{/* Print button */}
								<Button
									onClick={handlePrint}
									variant="light"
									color="blue"
									disabled={!client || !cart || cart.length === 0}
								>
									{config.translate.print[lang]}
								</Button>

								<NumberInput
									label={config.translate.price[lang]}
									placeholder={config.translate.price[lang]}
									value={selectedProdcutPrice || 0}
									onChange={(price) => setSelectedProductPrice(Math.round(price))}
									disabled={!selectedProduct}
									w={100}
								/>

								<Autocomplete
									label={config.translate.selectProduct[lang]}
									placeholder={config.translate.selectProduct[lang]}
									data={allProducts.flatMap((product) => {
										return {
											value: product._id,
											label: String(product.ID),
										};
									})}
									onChange={(val) => {
										let product = allProducts.find(
											(product) => product.ID === Number(val)
										);
										setSelectedProductPrice(Math.round(product?.price));
										setSelectedProduct(product);
										setProductAutocompleteValue(product?.ID);
									}}
									value={productAutocompleteValue}
								/>

								<NumberInput
									label={config.translate.weight[lang]}
									placeholder={config.translate.weight[lang]}
									value={selectedWeight || 0}
									onChange={(weight) => {
										if (!weight || typeof weight === "string") return;
										setSelectedWeight(
											weight ? parseFloat(weight).toFixed(3) : 0
										);
									}}
									w={100}
								/>

								<NumberInput
									label={config.translate.diameter[lang]}
									placeholder={config.translate.diameter[lang]}
									value={selectedDiameter || 0}
									onChange={setSelectedDiameter}
									w={100}
								/>
								<NumberInput
									label={config.translate.quantity[lang]}
									placeholder={config.translate.quantity[lang]}
									value={selectedQuantity || 0}
									onChange={setSelectedQuantity}
									w={100}
								/>

								<Button
									disabled={
										!(
											client &&
											selectedProduct &&
											selectedWeight &&
											selectedDiameter
										)
									}
									onClick={() => {
										if (
											!client ||
											!selectedProduct ||
											!selectedWeight ||
											!selectedDiameter
										) {
											Swal.fire({
												icon: "error",
												title: "Oops...",
												text: "Please fill all fields",
											});
											return;
										}
										addToCart({
											product: selectedProduct,
											weight: Number(selectedWeight).toFixed(3),
											diameter: selectedDiameter,
											quantity: selectedQuantity,
											price: Math.round(selectedProdcutPrice),
										});
										setProductAutocompleteValue("");
										setSelectedProduct(null);
										setSelectedProductPrice(0);
										setSelectedWeight(null);
										setSelectedDiameter(null);
										setSelectedQuantity(null);
									}}
								>
									<IconPlus size={20} />
								</Button>
							</Flex>
						</Grid.Col>
						<Divider my="lg" />
						<Divider my="xs" />
						<Table>
							<Table.Thead>
								<Table.Tr>
									<Table.Th></Table.Th>
									<Table.Th>{config.translate.product[lang]}</Table.Th>
									<Table.Th>{config.translate.weight[lang]}</Table.Th>
									<Table.Th>{config.translate.diameter[lang]}</Table.Th>
									<Table.Th>{config.translate.quantity[lang]}</Table.Th>
									<Table.Th>{config.translate.price[lang]}</Table.Th>
								</Table.Tr>
							</Table.Thead>
							<Table.Tbody>{rows}</Table.Tbody>
						</Table>
						<Divider my="lg" />
						<Grid.Col span={12}>
							<Flex justify={"space-between"}>
								<Card w={"100%"}>
									<Text weight={700}>{config.translate.paid[lang]}</Text>
									<Divider my="sm" />
									<NumberInput
										disabled={price === 0 || !price}
										value={paid}
										onChange={setPaid}
									/>
								</Card>
								<Card w={"100%"} ml={10} mr={10}>
									<Text weight={700}>{config.translate.debt[lang]}</Text>
									<Divider my="sm" />
									<Text weight={700} c={"blue"} size="xl">
										{debt}
									</Text>
								</Card>
								<Card w={"100%"}>
									<Text weight={700}>{config.translate.totalPrice[lang]}</Text>
									<Divider my="sm" />
									<Text weight={700} c={"cyan"} size="xl">
										{price}
									</Text>
								</Card>
							</Flex>
						</Grid.Col>
					</Grid>
				</Paper>

				<Group justify="center" mt="xl">
					<Button variant="default" onClick={() => history.goBack()}>
						{config.translate.cancel[lang]}
					</Button>
					<Button
						disabled={!client || !cart || cart.length === 0}
						onClick={async () => {
							let result = await UPDATE({
								route,
								id,
								modelSendToServer,
								setBusy,
								history,
								lang,
							});
						}}
					>
						{config.translate.save[lang]}
					</Button>
				</Group>
			</>
		);
}
